import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link } from 'gatsby';
import IconLibs from '../images/icon-libraries.svg';
import IconTerraform from '../images/terraform-icon.svg'
import IconIaC from '../images/IaC-icon.svg'
import IconPGreen from '../images/P-GreenRGB-icon.svg'
import IconAuth from '../images/authentication-icon.svg'


export default class CustomizeYourExperience extends React.Component {

        
    render() {
    
        return(
        <Layout>
            <SEO title="Customize Your Experience" />
            <section>
                <div className="md:h-3lane sm:2h-lane bg-gray-dark text-center text-4xl text-white content-center justify-center grid"> 
                Customize Your Experience
                </div>
            </section>
            <section className="bg-gray-xxl">
                <div className="grid py-3gut sm:flex sm:items-center justify-center">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-20 ">
                    <a href="/docs/ZG9jOjExMDI5NTUw-rest-api-overview">
                    <div className="max-w-sm bg-white hover:bg-gray-100 rounded overflow-hidden shadow-xl h-3lane w-3lane p-14 border border-gray-xlight">
                        <img className="h-5gut ml-5gut my-gut" src={IconPGreen}/>
                            <div className="p-10">
                            <div className="font-bold text-center mb-2">REST API</div>
                            <p className="text-gray-700 text-sm">
                            Use the REST API to access or manipulate configuration data in PagerDuty.
                            </p>
                            </div>
                    </div>
                    </a>
                    <a href="https://registry.terraform.io/providers/PagerDuty/pagerduty/latest/docs">
                    <div className="max-w-sm bg-white hover:bg-gray-100 rounded overflow-hidden shadow-xl h-3lane w-3lane p-14 border border-gray-xlight">
                            <img className="h-5gut ml-4gut my-gut" src={IconTerraform}/>
                            <div className="p-10">
                                <div className="font-bold text-center mb-2">Terraform Provider</div>
                                <p className="text-gray-700 text-sm">
                                Configure your PagerDuty account settings with code.
                                </p>
                            </div>
                    </div>
                    </a>
                    <a href="/docs/ZG9jOjExMDI5NTUx-authentication">
                    <div className="max-w-sm bg-white hover:bg-gray-100 rounded overflow-hidden shadow-xl h-3lane w-3lane p-14 border border-gray-xlight">
                            <img className="h-6gut ml-4gut mt-gut" src={IconAuth}/>
                            <div className="p-10">
                                <div className="font-bold text-center mb-2">Authentication</div>
                                <p className="text-gray-700 text-sm">
                                Authenticate with PagerDuty's REST API to begin building!
                                </p>
                            </div>
                    </div>
                    </a>
                </div>
                </div>
            </section>
        </Layout>
        )
    };

};

